import emitter from "tiny-emitter/instance"

let debugEventsEnabled = false

export const setEventDebug = debug => {
  debugEventsEnabled = debug
}

function debugEvent(method, args) {
  if (!debugEventsEnabled) return
  const [eventName, ...eventArgs] = args
  console.log(`event-bus.ts ${method} ${eventName}`, eventArgs)
}

export default {
  $on: (...args) => {
    debugEvent("on", args)
    return emitter.on(...args)
  },
  $once: (...args) => {
    debugEvent("once", args)
    return emitter.once(...args)
  },
  $off: (...args) => {
    debugEvent("off", args)
    return emitter.off(...args)
  },
  $emit: (...args) => {
    debugEvent("emit", args)
    return emitter.emit(...args)
  },
}
